import axios from "axios";
import qs from "qs";

const serverUrl = "https://rest-api.dentalmarketing.net/access/WebAPI/";
const devServerUrl = "https://localhost:44302/access/WebAPI/";
const isDev = false;

const authorize = async (context) => {
  return new Promise((resolve, reject) => {
    const sParams = {
      requestKey: "4d3d57e1-1d9b-4f4b-9f64-16574f211c27",
      action: "authorize",
    };

    try {
      if (!context.AUTH_CODE) {
        axios
          .post(isDev ? devServerUrl : serverUrl, sParams)
          .then((response) => {
            if (response) {
              context.AUTH_CODE = response.data.authorization;
              resolve(context.AUTH_CODE);
            }
          })
          .catch((error) => {
            context.AUTH_CODE = "";
            reject(context.AUTH_CODE);
          });
      } else {
        resolve(context.AUTH_CODE);
      }
    } catch (err) {
      context.AUTH_CODE = "";
      reject(context.AUTH_CODE);
    }
  });
};

export const sfListOrders = async (context, accountId, ini, end) => {
  const AUTH_CODE = await authorize(context); // Requests an authorization code before making actual calls

  if (context.SF_TOKEN) {
    const renewToken = (new Date() - context.SF_TOKEN_TIME) / 3600000;
    if (renewToken >= 2) {
      context.SF_TOKEN = undefined;
      context.SF_URL = undefined;
    }
  }

  /*****
   *
   * Query Orders from Salesforce
   * Adjust line RecordType.Name to filter orders by type
   *
   *******/
  const sParams = {
    requestKey: "4d3d57e1-1d9b-4f4b-9f64-16574f211c27",
    requestCode: AUTH_CODE,
    action: "sf-search",
    token: context.SF_TOKEN,
    url: context.SF_URL,
    search: [
      {
        sobject: "Order",
        fields: "Id,EffectiveDate,Order_Label__c,Dropbox_Folder_Upswell__c",
        filter: `AccountId='${accountId}' 
                AND EffectiveDate >= ${ini.toJSON().split("T")[0]}
                AND EffectiveDate <= ${end.toJSON().split("T")[0]}
                AND RecordType.Name IN ('Postcard Mailing', 'New Move-In', 'Internal Marketing Cards', 'DW')
                ORDER BY EffectiveDate DESC`,
      },
    ],
  };

  try {
    const { data } = await axios.post(
      isDev ? devServerUrl : serverUrl,
      sParams
    );

    const response = data;
    if (response.token) {
      context.SF_TOKEN = response.token;
      context.SF_URL = response.url;
      context.SF_TOKEN_TIME = new Date();
    }
    return response.Order;
  } catch (err) {
    return null;
  }
};

export const sfListExistingPatients = async (context, accountId) => {
  const AUTH_CODE = await authorize(context); // Requests an authorization code before making actual calls

  const sParams = {
    requestKey: "4d3d57e1-1d9b-4f4b-9f64-16574f211c27",
    requestCode: AUTH_CODE,
    action: "sf-search",
    search: [
      {
        sobject: "Patient__c",
        fields: "Id,First_Name__c,Last_Name__c,Source__c",
        filter: `Account__c='${accountId}' AND Source__c='Export'
                ORDER BY CreatedDate ASC`,
      },
    ],
  };

  try {
    const { data } = await axios.post(serverUrl, sParams);
    const response = data;
    return response.Patient__c;
  } catch (err) {
    return null;
  }
};

export const sfSavePatients = async (context, patients) => {
  const AUTH_CODE = await authorize(context); // Requests an authorization code before making actual calls

  if (context.SF_TOKEN) {
    const renewToken = (new Date() - context.SF_TOKEN_TIME) / 3600000;
    if (renewToken >= 2) {
      context.SF_TOKEN = undefined;
      context.SF_URL = undefined;
    }
  }

  const sfPatients = [];
  const toUpdatesfPatients = [];
  for (const p of patients) {
    const pInfo = {
      Id: p.Id ? p.Id : null,
      Name:
        p.FIRSTNAME || p.LASTNAME ? p.FIRSTNAME + " " + p.LASTNAME : p.FULLNAME,
      First_Name__c: p.FIRSTNAME,
      Last_Name__c: p.LASTNAME,
      Account__c: p.AccountId,
      Most_Recent_Order__c: p.OrderId,
      Last_Seen_Date__c: p.PatientDate,
      First_Visit_Date__c: p.PatientDate,
      Street_Address__c: p.ADDRESS,
      State__c: p.STATE,
      City__c: p.CITY,
      Zip_Code__c: p.ZIP,
      created_by_cruncher__c: true,
      Export_Date__c: new Date(),
      From_Patient_Export__c: true,
      Patient_Status__c: "Valid Patient",
      Source__c: "Export",
    };

    if (!pInfo.Id) sfPatients.push(pInfo);
    else toUpdatesfPatients.push(pInfo);
  }

  const b = {
    Request: "Create",
    Patients: sfPatients,
    ToUpdatePatients: toUpdatesfPatients,
  };
  const sParams = {
    requestKey: "4d3d57e1-1d9b-4f4b-9f64-16574f211c27",
    requestCode: AUTH_CODE,
    action: "sf-rest",
    token: context.SF_TOKEN,
    url: context.SF_URL,
    rest: [
      {
        actionName: "Create Patients",
        serviceName: "ImportPatients",
        body: JSON.stringify(b),
      },
    ],
  };

  try {
    const { data } = await axios.post(
      isDev ? devServerUrl : serverUrl,
      sParams
    );
    console.log(data);

    const response = data;
    if (response.token) {
      context.SF_TOKEN = response.token;
      context.SF_URL = response.url;
      context.SF_TOKEN_TIME = new Date();
    }
    return response;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const sfGetCSVFromDropbox = async (context, folder) => {
  const fileBlob = await dropboxSearch(
    decodeURIComponent(
      folder
        .replace("https://www.dropbox.com/home/DPM", "")
        .replace("https://www.dropbox.com/work/Client Services", "")
        .replace("https://www.dropbox.com/work/Client%20Services", "")
    ),
    ".csv"
  );
  console.log(fileBlob.toString());
  return fileBlob === null ? null : fileBlob.toString();
};

//******************* DROPBOX METHODS:::::::  */

// Define your constants here
const DROPBOX_TOKEN_URL = "https://api.dropboxapi.com/oauth2/token";
const REFRESH_TOKEN = process.env.REACT_APP_DROPBOX_REFRESH_TOKEN;
const CLIENT_ID = process.env.REACT_APP_DROPBOX_KEY;
const CLIENT_SECRET = process.env.REACT_APP_DROPBOX_SECRET;

async function getNewAccessToken() {
  try {
    // Define the request body
    const requestBody = qs.stringify({
      grant_type: "refresh_token",
      refresh_token: REFRESH_TOKEN,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
    });

    // Make the request using axios
    const response = await axios.post(DROPBOX_TOKEN_URL, requestBody, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    // Extract and return the access token
    return response.data.access_token;
  } catch (error) {
    console.error("Error fetching new access token:", error);
    return null;
  }
}

async function listFiles(folderPath, accessToken) {
  try {
    // Ensure the folder path starts with "/"
    const formattedPath = folderPath.startsWith("/")
      ? folderPath.replace(/%20/g, " ")
      : "/" + folderPath.replace(/%20/g, " ");

    // Create the request body
    const requestBody = {
      path: formattedPath, // Correctly formatted folder path
      recursive: false,
      include_media_info: false,
      include_deleted: false,
      include_has_explicit_shared_members: false,
      include_mounted_folders: false,
      include_non_downloadable_files: false,
    };

    // Send the POST request to Dropbox API
    const response = await axios.post(
      "https://api.dropboxapi.com/2/files/list_folder",
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json; charset=utf-8",
          "Dropbox-API-Path-Root":
            '{".tag": "namespace_id", "namespace_id": "5129306177"}',
          "Dropbox-Api-Select-User":
            "dbmid:AABKkrfjHV8p82VIMW1aL3PBvN_azb7bf70",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error listing files:", error.message);
    if (error.response) {
      console.error("Status Code:", error.response.status);
      console.error("Response Data:", error.response.data); // Logs Dropbox's error message
    }
    return null;
  }
}

// Download a file from Dropbox
async function getFile(filePath, fileName, accessToken) {
  try {
    const path = `${filePath.replace(/%20/g, " ")}/${fileName}`;
    const pathJson = {
      path: path,
    };

    // Make the request to download the file
    const response = await axios.post(
      "https://content.dropboxapi.com/2/files/download",
      null,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Dropbox-API-Arg": JSON.stringify(pathJson).replace(/\r\n\s*/g, ""), // Remove newlines and spaces
          "Content-Type": "text/plain",
          "Dropbox-API-Path-Root":
            '{".tag": "namespace_id", "namespace_id": "5129306177"}',
          "Dropbox-Api-Select-User":
            "dbmid:AABKkrfjHV8p82VIMW1aL3PBvN_azb7bf70",
        },
        responseType: "arraybuffer", // For binary data
      }
    );

    return bufferToText(response.data); // Return the raw data if needed
  } catch (error) {
    console.error("Error downloading file:", error.message);
    return null;
  }
}

// Main DropboxSearch function
export async function dropboxSearch(folder, fileType) {
  const accessToken = await getNewAccessToken();
  console.log(folder);
  // If no specific file name is given, search for files by file extension
  try {
    const listResponse = await listFiles(folder, accessToken);
    if (listResponse && listResponse.entries) {
      for (const item of listResponse.entries) {
        const fileName = item.name;
        if (fileName.endsWith(fileType)) {
          return await getFile(folder, fileName, accessToken);
        }
      }
    }
  } catch (error) {
    console.error("Error searching Dropbox:", error);
  }
  return "";
}

const bufferToText = (arrayBuffer) => {
  const decoder = new TextDecoder("utf-8"); // Specify encoding if known
  return decoder.decode(arrayBuffer);
};
